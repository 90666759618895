import React, { useState, useContext, useEffect } from "react";
import { withPrefix } from "gatsby";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import loadable from "@loadable/component";

import StoreContext from "../context/storeContext";
import ShopifyContext from "../context/shopifyContext";
import StoreInfo from "../fixtures/store-info.json";

// Utils
import {
  getCarouselSettings,
  getMediaBlockProps,
  getFromPrice,
} from "../utils";
import CONSTANTS from "../constants";

// Styles
import * as headerStyles from "../styles/header-styles.module.scss";
import * as iconStyles from "../styles/icon-styles.module.scss";

// Lattice Components
const Lattice = loadable(() => import("lattice/lib/components/Lattice"));
const LatticeGrid = loadable(() =>
  import("lattice/lib/components/LatticeGrid"),
);
const LatticeDevLines = loadable(() =>
  import("lattice/lib/components/LatticeDevLines"),
);

// Internal Components
const SEO = loadable(() => import("../components/functional/seo"));
const Header = loadable(() => import("../components/organisms/header"));
const Footer = loadable(() => import("../components/organisms/footer"));
const ProductPanel = loadable(() =>
  import("../components/organisms/product-panel"),
);
const MediaBlock = loadable(() =>
  import("../components/organisms/media-block"),
);
const MediaCarousel = loadable(() =>
  import("../components/organisms/media-carousel"),
);
const Button = loadable(() => import("../components/atoms/button"));
const Accordion = loadable(() => import("../components/molecules/accordion"));
const Image = loadable(() => import("../components/atoms/image"));
const ModalCarousel = loadable(() =>
  import("../components/organisms/modal-carousel"),
);
const CarouselClick = loadable(() =>
  import("../components/functional/carousel-click"),
);

function DemoPage({ type, dev, section }) {
  const { updateCurrentBundle } = useContext(StoreContext);

  const [showReviewModal, setShowReviewModal] = useState();
  const [reviewModalContent, setReviewModalContent] = useState();
  const [showSliderModal, setShowSliderModal] = useState(false);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const activateSliderModal = (index) => {
    setShowSliderModal(true);
    setCurrentSlideIndex(index);
  };

  const demoSection = section;
  let demoJsx = null;

  const carouselClasses = demoSection.classNames
    ? [...demoSection.classNames, `${type}__carousel`].concat(
        demoSection.useSlider === false ? [`${type}__group`] : [],
      )
    : `${type}__carousel${
        demoSection.useSlider === false ? ` ${type}__group` : ""
      }`;
  if (demoSection.mediaBlocks) {
    if (type === "product-hero") {
      demoJsx = (
        <LatticeGrid fullWidth innerGrid className="product-hero">
          <MediaCarousel
            className={carouselClasses}
            title={demoSection.headline}
            {...getCarouselSettings(type, {
              images: demoSection.mediaBlocks,
            })}>
            {demoSection.mediaBlocks.map((mediaBlock) => {
              if (mediaBlock.type === type) {
                const imageSources =
                  mediaBlock.mobileImage && mediaBlock.image
                    ? {
                        desktop: mediaBlock.image,
                        mobile: mediaBlock.mobileImage,
                      }
                    : null;
                return (
                  <Image
                    image={imageSources || mediaBlock.image}
                    alt="Product Detail"
                    className="product-slide"
                    loading="eager"
                    key={mediaBlock.id}
                  />
                );
              }
              return null;
            })}
          </MediaCarousel>
        </LatticeGrid>
      );
    } else if (type === "collection") {
      demoJsx = (
        <MediaCarousel
          className={carouselClasses}
          backgroundColor={demoSection.backgroundColor}
          title={demoSection.headline}
          {...getCarouselSettings(type)}>
          {demoSection.mediaBlocks[0].products.map((collectionProduct) => (
            <MediaBlock
              type="product-card"
              className="product__product-card"
              {...getMediaBlockProps({
                ...collectionProduct,
                type,
              })}
              key={collectionProduct.id}
            />
          ))}
        </MediaCarousel>
      );
    } else {
      demoJsx = (
        <MediaCarousel
          className={carouselClasses}
          title={demoSection.headline}
          summary={demoSection.summary}
          backgroundColor={demoSection.backgroundColor}
          backgroundImage={demoSection.backgroundImage}
          mobileBackgroundImage={demoSection.mobileBackgroundImage}
          image={demoSection.image}
          mobileImage={demoSection.mobileImage}
          useWrapper={type === "lifestyle"}
          wrapperComponent={type === "lifestyle" ? "div" : null}
          useSlider={demoSection.useSlider !== false}
          {...getCarouselSettings(type, {
            images: demoSection.mediaBlocks,
          })}>
          {demoSection.mediaBlocks.map((mediaBlock) => {
            const blockClasses = mediaBlock.classNames
              ? [...mediaBlock.classNames, `demo__${mediaBlock.type}`]
              : `demo__${mediaBlock.type}`;
            if (mediaBlock.type === type) {
              return (
                <MediaBlock
                  type={mediaBlock.type}
                  className={blockClasses}
                  id={mediaBlock.idName}
                  {...getMediaBlockProps({
                    ...mediaBlock,
                    carouselSlider: demoSection.useSlider !== false,
                    carouselTextColor:
                      iconStyles[demoSection.backgroundColor] ||
                      iconStyles.standardWhite,
                  })}
                  key={mediaBlock.id}
                  grid={false}
                />
              );
            }
            return null;
          })}
        </MediaCarousel>
      );
    }
  } else if (type === "review") {
    demoJsx = (
      <MediaCarousel
        className={carouselClasses}
        reviewInfo={demoSection.details}
        reviewStars={demoSection.images.reviewStars}
        reviewModal={{
          showReviewModal,
          reviewModalContent,
          setShowReviewModal,
        }}
        {...getCarouselSettings(type)}
        key={demoSection.id}>
        {demoSection.list.map((review) => (
          <MediaBlock
            type={type}
            className={`product__${type}`}
            {...getMediaBlockProps({
              type,
              review,
              reviewStar: demoSection.images.smallReviewStar,
              setShowReviewModal,
              setReviewModalContent,
            })}
            key={review.id}
          />
        ))}
      </MediaCarousel>
    );
  } else if (type === "header") {
    demoJsx = (
      <Header
        menus={demoSection.menus && demoSection.menus.contentfulMenus.edges}
        layout="home"
        extras={{
          storeContext: StoreContext,
          shopifyContext: ShopifyContext,
          reviewStars: StoreInfo.data.storeInfo.edges[0].node.reviewStars,
          headerStyles,
          ...CONSTANTS,
          domain: process.env.DOMAIN,
          OOS_EMAIL_LIST: process.env.OOS_EMAIL_LIST,
          PRE_LAUNCH_LIST: process.env.PRE_LAUNCH_LIST,
          FOOTER_EMAIL_LIST: process.env.FOOTER_EMAIL_LIST,
          KLAVIYO_API_KEY: process.env.KLAVIYO_API_KEY,
        }}
      />
    );
  } else if (type === "footer") {
    demoJsx = (
      <Footer
        menus={demoSection.menus && demoSection.menus.contentfulMenus.edges}
      />
    );
  } else if (type === "product-panel") {
    const stubFunction = () => {};

    const [active, setActive] = useState(
      demoSection.product.shopifyProduct.variants.edges[0].node,
    );
    const panelProps = {
      ...demoSection,
      setActiveVariant: setActive,
      active,
      extras: {
        ...demoSection.extras,
        storeContext: StoreContext,
        CONSTANTS: {
          ...CONSTANTS,
          OOS_EMAIL_LIST: process.env.OOS_EMAIL_LIST,
          PRE_LAUNCH_LIST: process.env.PRE_LAUNCH_LIST,
          FOOTER_EMAIL_LIST: process.env.FOOTER_EMAIL_LIST,
          DEV: process.env.DEV,
          KLAVIYO_API_KEY: process.env.KLAVIYO_API_KEY,
        },
      },
      setActiveParams: {
        quantity: stubFunction,
        size: stubFunction,
        firmness: stubFunction,
        color: stubFunction,
        value: stubFunction,
      },
      setShowOOS: stubFunction,
      setShowDiscount: stubFunction,
      setShowDetails: stubFunction,
      atcAction: stubFunction,
    };

    demoJsx = (
      <LatticeGrid fullWidth innerGrid className="product-hero">
        <ProductPanel {...panelProps} />
      </LatticeGrid>
    );
  } else if (type === "bundle-panel") {
    const stubFunction = () => {};

    // Initialize current bundle context
    useEffect(() => {
      updateCurrentBundle({
        started: false,
        title: demoSection.bundle.title,
        heading: demoSection.bundle.heading,
        index: 0,
        items: demoSection.bundle.products.map((bp, index) => {
          const testVariant = bp.product.shopifyProduct.variants.edges.find(
            (v) => v.node.availableForSale && v.node.title.includes("King"),
          );
          return {
            product: bp.product,
            variant: index === 1 && testVariant ? testVariant.node : null,
            tempVariant: null,
            itemPos: null,
            itemHeight: null,
          };
        }),
      });
    }, []);

    const freeTrialDetails =
      demoSection.bundle.productDetails &&
      demoSection.bundle.productDetails.filter(
        (detail) => detail.title === "Free Trial Details",
      );
    const nonFreeTrialDetails =
      demoSection.bundle.productDetails &&
      demoSection.bundle.productDetails.filter(
        (detail) => detail.title !== "Free Trial Details",
      );
    const detailsText =
      nonFreeTrialDetails &&
      nonFreeTrialDetails.map(
        (detail) =>
          `${detail.shortText ? `<h4>${detail.shortText}</h4>` : ""}${
            detail.longText.childMarkdownRemark.html
          }`,
      );

    demoJsx = (
      <LatticeGrid
        fullWidth
        innerGrid
        className="bundle-product-hero product-hero">
        <div className="product-panel">
          <div className="product-panel__grid">
            <h1 className="product-panel__title">
              {demoSection.bundle.heading || demoSection.bundle.title}
            </h1>
            <h2 className="product-panel__price">
              $
              {demoSection.bundle.products.reduce(
                (a, b) =>
                  a + getFromPrice(b.product.shopifyProduct.variants.edges),

                0,
              )}
            </h2>

            <p className="product-panel__short p3">
              {demoSection.bundle.shortDescription}
            </p>

            {demoSection.bundle.products.map((bundleProduct, index) => {
              const bundleProductBlocks =
                bundleProduct.product.sliderImages.map((img, i) => ({
                  type: "product-hero",
                  className: "product-slide",
                  loading: "eager",
                  fadeIn: false,
                  alt: "Product Detail",
                  image: {
                    gatsbyImageData: img.gatsbyImageData,
                  },
                  mobileImage:
                    bundleProduct.product.mobileSliderImages &&
                    bundleProduct.product.mobileSliderImages[i]
                      ? {
                          gatsbyImageData:
                            bundleProduct.product.mobileSliderImages[i]
                              .gatsbyImageData,
                        }
                      : null,
                  id: img.contentful_id,
                }));
              return (
                <div
                  className={`bundle-item-wrapper ${
                    index === 0 ? "bundle-item-wrapper__active" : ""
                  }`}
                  key={`${bundleProduct.product.id}${
                    bundleProduct.product.addon ? "-addon" : ""
                  }`}>
                  <div className="bundle-item-wrapper-ref">
                    <div
                      className={`bundle-item-hero ${
                        index !== 0 ? "bundle-item-hero__closed" : ""
                      } product-hero ${
                        index === 0 ? "bundle-item-hero__active" : ""
                      }`}>
                      {index === 0 ? (
                        <>
                          <ModalCarousel
                            sliderBlocks={bundleProductBlocks}
                            open={showSliderModal}
                            handleClose={() => setShowSliderModal(false)}
                            currentSlide={currentSlideIndex}
                            headline="Demo Product"
                          />
                          <MediaCarousel
                            className="product-hero__carousel"
                            title={bundleProduct.product.headline}
                            useWrapper
                            wrapperComponent="div"
                            wrapperClassName="product-hero__carousel-wrapper"
                            {...getCarouselSettings("product-hero", {
                              images: bundleProductBlocks,
                              mode: "panel",
                            })}>
                            {bundleProductBlocks.map(
                              (mediaBlock, mediaBlockIndex) => {
                                if (mediaBlock.type === "product-hero") {
                                  const imageSources =
                                    mediaBlock.mobileImage && mediaBlock.image
                                      ? {
                                          desktop: mediaBlock.image,
                                          mobile: mediaBlock.mobileImage,
                                        }
                                      : null;
                                  return (
                                    <CarouselClick
                                      onClick={() =>
                                        activateSliderModal(mediaBlockIndex)
                                      }
                                      key={mediaBlock.id}>
                                      <Image
                                        image={imageSources || mediaBlock.image}
                                        alt="Product Detail"
                                        className="product-slide"
                                        loading="eager"
                                      />
                                    </CarouselClick>
                                  );
                                }
                                return null;
                              },
                            )}
                          </MediaCarousel>
                        </>
                      ) : null}
                      <ProductPanel
                        {...{
                          ...bundleProduct,
                          bundleProduct: true,
                          bundlePosition: index,
                          setActiveVariant: stubFunction,
                          activeVariant:
                            index === 1
                              ? bundleProduct.product.shopifyProduct.variants.edges.find(
                                  (v) =>
                                    v.node.availableForSale &&
                                    v.node.title.includes("King"),
                                ).node
                              : bundleProduct.product.shopifyProduct.variants
                                  .edges[0].node,
                          extras: {
                            ...demoSection.extras,
                            storeContext: StoreContext,
                            CONSTANTS: {
                              ...CONSTANTS,
                              OOS_EMAIL_LIST: process.env.OOS_EMAIL_LIST,
                              PRE_LAUNCH_LIST: process.env.PRE_LAUNCH_LIST,
                              FOOTER_EMAIL_LIST: process.env.FOOTER_EMAIL_LIST,
                              DEV: process.env.DEV,
                              KLAVIYO_API_KEY: process.env.KLAVIYO_API_KEY,
                            },
                          },
                          setActiveParams: {
                            quantity: stubFunction,
                            size: stubFunction,
                            firmness: stubFunction,
                            color: stubFunction,
                            value: stubFunction,
                          },
                          setShowOOS: stubFunction,
                          setShowDiscount: stubFunction,
                          setShowDetails: stubFunction,
                          atcAction: stubFunction,
                        }}
                      />
                    </div>
                  </div>
                </div>
              );
            })}

            <Button
              color="buffyOrange"
              className="product-panel__atc"
              inactive
              // forwardRef={productCta}
              onClick={stubFunction}
              label={demoSection.bundle.heading}>
              Start Free Trial
            </Button>

            <Accordion
              title="Details"
              tag="h3"
              onOpen={stubFunction}
              onClose={stubFunction}
              className="product__accordion product__accordion--first">
              <div
                className="accordion__content-list"
                dangerouslySetInnerHTML={{
                  __html: detailsText.join(""),
                }}
              />
            </Accordion>
            <Accordion
              title={freeTrialDetails[0].shortText}
              tag="h3"
              className={`product__accordion ${
                nonFreeTrialDetails ? "" : "product__accordion--first"
              }`}>
              <div
                className="accordion__content-list"
                dangerouslySetInnerHTML={{
                  __html: freeTrialDetails[0].longText.childMarkdownRemark.html,
                }}
              />
            </Accordion>
          </div>
        </div>
        {/* <ProductPanel {...panelProps} /> */}
      </LatticeGrid>
    );
  } else {
    const blockClasses = demoSection.classNames
      ? [...demoSection.classNames, `demo__${type}`]
      : `demo__${type}`;
    demoJsx = (
      <MediaBlock
        type={type}
        className={blockClasses}
        {...getMediaBlockProps({
          ...demoSection,
          type,
          extras: {
            DEV: process.env.DEV,
            KLAVIYO_API_KEY: process.env.KLAVIYO_API_KEY,
            CORS_PROXY: CONSTANTS.CORS_PROXY,
          },
        })}
      />
    );
  }

  const isBrowser = typeof window !== `undefined`;
  const isIFrame = isBrowser && window.self !== window.top;
  return (
    <Lattice className="buffy buffy-demo" hideOverflow>
      {dev ? <LatticeDevLines /> : null}
      <SEO title="Buffy Demo" />
      {isIFrame ? (
        <Helmet>
          <script src={withPrefix("iframeResizer.min.js")} />
        </Helmet>
      ) : null}
      {demoJsx}
    </Lattice>
  );
}

DemoPage.propTypes = {
  type: PropTypes.string.isRequired,
  dev: PropTypes.bool,
  section: PropTypes.objectOf(PropTypes.any),
};

DemoPage.defaultProps = {
  dev: true,
  section: {},
};

export default DemoPage;
