// extracted by mini-css-extract-plugin
export var eucalyptus = "standardWhite";
export var lightEucalyptus = "eucalyptus";
export var buffyOrange = "standardWhite";
export var lightBuffyOrange = "defaultText";
export var buffyPurple = "standardWhite";
export var lightBuffyPurple = "defaultText";
export var sand = "defaultText";
export var oatmeal = "defaultText";
export var standardWhite = "defaultText";
export var standardBlack = "standardWhite";